<template>
  <div id="tpl-endorsements">
    <b-container id="submit-endorsement-form">
      <div v-if="!isLoggedIn">
        <b-row class="flex-column flex-lg-row">
          <b-col class="p-0" lg="5">
            <h3>Endorse {{ candidateFirstName }}</h3>
            <p>
              To endorse {{ candidateFirstName }} please login to your myWGA
              account.
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button variant="primary" type="button" @click="onAuthenticate"
              >LOGIN</b-button
            >
          </b-col>
        </b-row>
      </div>
      <div v-else-if="isLoggedIn && isEligible">
        <template v-if="temporaryDisableEndorsementForm">
          <div>
            <b-row class="flex-column flex-lg-row">
              <b-col class="p-3">
                <h3>
                  Endorsement submission is paused for a few minutes. Please
                  wait or come back later.
                </h3>
                <p></p>
              </b-col>
            </b-row>
          </div>
        </template>
        <template v-else>
          <ValidationObserver
            ref="observer"
            v-slot="{ invalid }"
            v-if="!submitted"
          >
            <b-form ref="endorsementForm" @submit.prevent="onSubmit">
              <b-form-group
                label="Sign Petition for Candidacy"
                v-if="candidate.isPetition"
                class="petition-group"
              >
                <b-form-checkbox
                  class="petition-checkbox"
                  v-model="signedPetition"
                >
                  I, the undersigned Current-Active member, hereby petition the
                  Guild in support of the candidacy of
                  <span id="candidate-name-petition">{{ candidate.name }}</span>
                  for the
                  <span id="candidate-position-petition"
                    >{{ setPosition(candidate.positionId) }} position</span
                  >.
                </b-form-checkbox>
              </b-form-group>

              <!-- TODO: LOOP ENDORSEMENTS -->

              <b-form-group
                v-if="candidate.endorsementStatements.length > 0"
                label="Submit Member Endorsements"
                v-slot="{ ariaDescribedby }"
                class="endorsement-group"
              >
                <p class="check-endorsement">
                  Please check which endorsement statement you are signing on
                  to. You may sign more than one statement.
                </p>
                <p class="mb-4">
                  By providing your name and identifying information below, you
                  are endorsing the following statement, including its exact
                  wording and punctuation, for use in the relevant WGAW election
                  campaign:
                </p>
                <b-form-checkbox-group
                  class="nonCandidate-checkbox"
                  :aria-describedby="ariaDescribedby"
                  name="submit-endorsement"
                  v-for="(statement, i) in candidate.endorsementStatements"
                  :key="i"
                  v-model="formDetail.endorsementStatementIds"
                >
                  <b-form-checkbox
                    class="endorsement-checkbox"
                    :value="statement.id"
                    :state="true"
                  >
                    <p
                      class="statement-text"
                      v-html="formatHash(statement.text)"
                    ></p>
                  </b-form-checkbox>
                </b-form-checkbox-group>
                <p>
                  I endorse the preceding statement and authorize publication of
                  my name as an endorser of the statement for all campaign
                  purposes.
                </p>
              </b-form-group>

              <b-row>
                <b-col cols="12" md="6" lg="6">
                  <ValidationProvider
                    name="Name"
                    rules="required"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="firstName-group"
                      class="form-input"
                      label="Name *"
                      label-for="first-name"
                    >
                      <!-- <b-form-input id="first-name" v-model="formDetail.firstName" placeholder="Enter Name"
                      :state="getValidationState(validationContext)" disabled></b-form-input> -->
                      <b-form-select
                        :state="getValidationState(validationContext)"
                        v-model="selectedName"
                        :options="namesOptions"
                      ></b-form-select>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="6"> </b-col>
                <b-col cols="12" md="6" lg="4">
                  <ValidationProvider
                    name="Email"
                    rules="required|email"
                    v-slot="validationContext"
                  >
                    <b-form-group
                      id="email-group"
                      class="form-input mb-lg-0"
                      label="Email *"
                      label-for="email"
                      description="We'll never share your email with anyone else."
                    >
                      <b-form-input
                        id="email"
                        v-model="formDetail.email"
                        type="email"
                        placeholder="Enter Email"
                        :state="getValidationState(validationContext)"
                        disabled
                      ></b-form-input>
                      <b-form-invalid-feedback>
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="8">
                  <ValidationProvider name="Positions">
                    <b-form-group
                      id="position-group"
                      class="form-input mb-lg-0"
                      label="Position"
                      label-for="position"
                    >
                      <b-form-checkbox-group
                        id="position"
                        v-model="selectedPositions"
                        :options="positions"
                        name="position"
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>

              <b-row class="m-0"> </b-row>

              <!-- <b-button type="submit">add endorsement</b-button> -->
              <b-button
                variant="primary"
                :disabled="submitting || invalid"
                type="submit"
              >
                <b-spinner small v-if="submitting"></b-spinner>
                <span class="ml-3" v-if="submitting">{{ statusText }}</span>
                <span v-else>{{ statusText }}</span>
              </b-button>
            </b-form>
          </ValidationObserver>
          <div v-else>
            <b-row class="flex-column flex-lg-row">
              <b-col class="p-3">
                <h3>You have successfully endorsed {{ candidateFirstName }}</h3>
                <p>
                  Your endorsement of {{ candidateFirstName }} can be seen in
                  the list below. Thank you for your endorsement.
                </p>
              </b-col>
            </b-row>
          </div>
        </template>
      </div>
      <div v-else>
        <b-row class="flex-column flex-lg-row">
          <b-col class="p-3">
            <h3>Looks like you are not eligible to endorse a candidate.</h3>
            <p>
              To Endorse a Candidate your member status must be in good
              standing. If you feel that your account is in good standing and
              should be able to endorse a candidate please use the link below to
              contact online services.
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button
              variant="primary"
              href="https://apps.wga.org/forms/contactus.aspx?email=membertechsupport"
              target="_blank"
              >CONTACT SUPPORT</b-button
            >
          </b-col>
        </b-row>
      </div>
    </b-container>
    <!-- Hide this if No Endorsements -->
  </div>
</template>

<script>
import Endorsement from "@/models/Endorsement";
import RecaptchaMixin from "@/mixins/recaptcha.mixin";
import * as CandidateService from "@/services/CandidateService2";
import * as SecurityService from "@/services/SecurityService";
import { setPosition } from "@/services/helpers";

export default {
  mixins: [RecaptchaMixin],
  data: () => ({
    title: "Endorsements",
    formDetail: new Endorsement(),
    signedPetition: true,
    signedEndorsement: false,
    submitting: false,
    submitted: false,
    selectedPositions: [],
    positions: [
      {
        text: "Current or former officer or Board of Directors member",
        value: 4
      },
      {
        text: "Current or former negotiating committee member",
        value: 3
      },
      {
        text: "Current or former WGAW PAC Board member",
        value: 5
      },
      {
        text: "Current or former committee chair or vice chair",
        value: 2
      },
      {
        text: "Current or former captain",
        value: 1
      }
    ],
    statusText: "Submit",
    selectedEndorsements: [],
    showEndorsements: false,
    endorsementTableItems: [],
    recaptchaSuccess: false,
    isPreview: false,
    isLoggedIn: false,
    loggedInId: null,
    isEligible: false,
    selectedName: "",
    namesOptions: [],
    temporaryDisableEndorsementForm: false,
    intervalId: null
  }),
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    },
    recaptchaSiteKey: () => {
      return process.env.RECAPTCHA_SITE_KEY;
    },
    candidateFirstName() {
      return this.candidate.name.split(" ")[0];
    },
    settings() {
      return this.$store.getters["settings/current"];
    }
  },
  created() {
    this.resetStatementCheckbox();
    this.hasSubmittedEndorsements();
    this.checkPreview();
  },
  async mounted() {
    await this.checkAuthentication();
    await this.hasEndorsed();
    const intervalTime = 10000;

    this.intervalId = setInterval(() => {
      const currentDateTime = new Date();
      const deadline = new Date(this.settings.lastDateOfEndorsementSubmission);
      const diffInMinutes = this.diffInMinutes(currentDateTime, deadline);

      if (currentDateTime > deadline && diffInMinutes <= 5) {
        this.temporaryDisableEndorsementForm = true;
      } else {
        this.temporaryDisableEndorsementForm = false;
      }
    }, intervalTime);
  },
  beforeDestroy() {
    // Clear the interval when the component is destroyed
    clearInterval(this.intervalId);
  },
  methods: {
    diffInMinutes(dt2, dt1) {
      // Calculate the difference in milliseconds between the two provided dates and convert it to seconds
      var diff = (dt2.getTime() - dt1.getTime()) / 1000;
      // Convert the difference from seconds to minutes
      diff /= 60;
      // Return the absolute value of the rounded difference in minutes
      return Math.abs(Math.round(diff));
    },
    formatHash(text) {
      const words = text.split(" ");
      const textArray = words.map(w => {
        if (w.startsWith("#")) {
          w = `<span class="highlights">${w}</span>`;
        }
        return w;
      });
      return textArray.join(" ");
    },
    setPosition: setPosition,
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    checkPreview() {
      if (document.title.includes("Preview")) {
        this.isPreview = true;
      }
    },
    async checkAuthentication() {
      const response = await SecurityService.AuthenticateMyWgaUserAsync({
        redirect: window.location.href,
        positionId: this.candidate.positionId
      });
      //console.log(response);
      if (response && response.authenticated) {
        this.isLoggedIn = true;
        this.isEligible = response.eligible;
        this.namesOptions = response.names.map(x => {
          let name = `${x.firstName}`;
          if (x.middleName) name += ` ${x.middleName}`;
          if (x.lastName) name += ` ${x.lastName}`;
          return {
            text: name,
            value: `${x.firstName}|${x.middleName}|${x.lastName}`
          };
        });
        if (this.namesOptions.length > 0)
          this.selectedName = this.namesOptions[0].value;
        // this.formDetail.firstName = response.firstName;
        // this.formDetail.middleName = response.middleName;
        // this.formDetail.lastName = response.lastName;
        this.formDetail.email = response.email;
        this.formDetail.last4DigitSsnOrMemberId = response.entityId.toString();
        this.loggedInId = response.entityId.toString();
      }
    },
    async hasEndorsed() {
      const data = {
        userId: this.candidate.userId,
        memberId: this.loggedInId
      };
      var response = await CandidateService.HasEndorsedAsync(data);
      this.submitted = response.data;
    },
    resetStatementCheckbox() {
      let ids = this.candidate.endorsementStatements.map(x => x.id);
      if (ids.length > 0) {
        ids.length = 1;
        this.formDetail.endorsementStatementIds = ids;
      }
    },
    hasSubmittedEndorsements() {
      this.showEndorsements =
        this.candidate.endorsementStatementsTableItems.length > 0
          ? true
          : false;
    },
    hasEndorsements: (statements, endorsements) => {
      if (!statements || !endorsements) return false;
    },
    colorCheck(id, positions) {
      let pos = positions.split(",");
      pos = pos.map(x => {
        return parseInt(x);
      });

      return pos.some(x => x === id);
    },
    async onAuthenticate() {
      const response = await SecurityService.AuthenticateMyWgaUserAsync({
        redirect: window.location.href,
        positionId: this.candidate.positionId
      });
      if (response && !response.authenticated) {
        window.location.href = response.redirect;
      }
    },
    async onSubmit() {
      this.submitting = true;
      this.statusText = "Submitting...";
      const recaptchaResponse = await this.recaptchaCallback();
      if (!recaptchaResponse) {
        alert("Verification failed! Try again later.");
        this.submitting = false;
        this.statusText = "Submit";
        return;
      }

      this.formDetail.candidateId = this.candidate.id;
      this.formDetail.userId = this.candidate.userId;
      this.formDetail.position = this.selectedPositions.join();
      this.formDetail.clientIpAddress = null;
      this.formDetail.firstName = this.selectedName.split("|")[0];
      this.formDetail.middleName = this.selectedName.split("|")[1];
      this.formDetail.lastName = this.selectedName.split("|")[2];
      this.formDetail.position = this.formDetail.position.replace(/]|[[]/g, "");
      try {
        if (this.formDetail.endorsementStatementIds.length > 0) {
          await CandidateService.SubmitEndorsementAsync(this.formDetail);
        }

        if (this.candidate.isPetition && this.signedPetition) {
          await CandidateService.SubmitPetitionAsync(this.formDetail);
        }

        this.$refs.observer.reset();
        setTimeout(() => {
          this.statusText = "Sent!";
          this.submitting = false;
          this.submitted = true;
          this.selectedPositions = [];
          this.formDetail = new Endorsement();
          this.resetStatementCheckbox();
          this.signedPetition = true;
          this.statusText = "Submit";
        }, 1000);
      } catch (error) {
        this.statusText = "Error occurred!";
        this.submitting = false;
        const faileData = {
          error: error.message,
          json: JSON.stringify(this.formDetail)
        };
        CandidateService.SendFailedEndorsementEmailAsync(faileData);
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.form-control.is-invalid {
  border-color: #dc3545 !important;
}

.statement-text {
  margin: 0;

  ::v-deep span.highlights {
    color: #0069d9;
    font-weight: bold;
  }
}
</style>
